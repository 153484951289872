import React from 'react';
import { Box, Button, IconButton, Alert, Typography } from '@og-pro/ui';
import {
    Download as DownloadIcon,
    CheckCircle as CheckCircleIcon,
    DoNotDisturb as DoNotDisturbIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { AgGridReactLegacy } from '../../../../components';
import { RejectionReasonModal } from '../../../../components/contracts/ContractDetails/GovernmentPendingDocuments/RejectionReasonModal';
import { contractAttachmentApproval } from '../../../../actions/contracts';
import { getContractJS } from '../../../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import AdjustableTextRenderer from './AdjustableTextRenderer';

const PendingAttachments = () => {
    const styles = require('../index.scss');
    const gridApiRef = React.useRef(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [attachmentToReject, setAttachmentToReject] = React.useState(null);
    const contract = useSelector(getContractJS);
    const dispatch = useDispatch();
    const rows = contract.pendingAttachments;

    const approvalHandler = (attachmentId, isApproved, data) => {
        setIsSubmitting(true);
        setAttachmentToReject(null);
        dispatch(
            contractAttachmentApproval(contract.id, attachmentId, { isApproved, ...data })
        ).finally(() => {
            setIsSubmitting(false);
        });
    };

    const approveAttachment = (attachmentId) => () => {
        dispatch(
            showConfirmationSimpleModal(
                () => {
                    approvalHandler(attachmentId, true);
                },
                {
                    useOpenGovStyle: true,
                }
            )
        );
    };

    const columns = [
        {
            flex: 2.5,
            headerName: 'File Name',
            cellRendererFramework: (params) => AdjustableTextRenderer(params.data.filename),
        },
        {
            field: 'type',
            flex: 1.2,
            headerName: 'Document Type',
            cellRendererFramework: (params) => params.data.tags.map((tag) => tag.name).join(', '),
        },
        {
            field: 'created_at',
            flex: 1,
            headerName: 'Date Added',
            cellRendererFramework: (params) =>
                params.value !== null
                    ? new Date(params.value).toLocaleDateString('en-us', { timeZone: 'UTC' })
                    : null,
        },
        {
            headerName: 'Notes from Vendor',
            flex: 2,
            cellRendererFramework: (params) =>
                AdjustableTextRenderer(params.data.contractAttachment.notes),
        },
        {
            cellClass: styles.iconCells,
            cellRendererFramework: (params) => (
                <Box alignItems="center" display="flex" justifyContent="flex-end" pt={0.5}>
                    <Box sx={{ mr: 1 }}>
                        <Button
                            disabled={isSubmitting}
                            onClick={approveAttachment(params.data.id)}
                            qaTag="contractDocuments-approvePendingAttachment"
                            sx={{
                                color: capitalDesignTokens.foundations.colors.pear700,
                                gap: 0.5,
                            }}
                        >
                            <CheckCircleIcon fontSize="small" /> Approve
                        </Button>
                    </Box>
                    <Box sx={{ mr: 1 }}>
                        <Button
                            disabled={isSubmitting}
                            onClick={() => setAttachmentToReject(params.data)}
                            qaTag="contractDocuments-rejectPendingAttachment"
                            sx={{
                                color: capitalDesignTokens.foundations.colors.ruby700,
                                gap: 0.5,
                            }}
                        >
                            <DoNotDisturbIcon fontSize="small" /> Reject
                        </Button>
                    </Box>
                    <Box>
                        <IconButton
                            component="a"
                            download
                            href={params.data?.url}
                            qaTag="contractDocuments-downloadPendingAttachment"
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Box>
                </Box>
            ),
            width: '262px',
        },
    ];

    const defaultColDef = {
        editable: false,
        suppressMenu: true,
        resizable: false,
        headerClass: styles.headerWhite,
        cellClass: styles.cell,
    };

    return (
        <Box>
            {rows.length > 0 && (
                <Box>
                    <Alert color="red" severity="warning" sx={{ borderRadius: '4px 4px 0px 0px' }}>
                        <Typography
                            color={capitalDesignTokens.foundations.colors.gray1000}
                            variant="h3"
                        >
                            Documents Pending Approval
                        </Typography>
                        <Typography
                            color={capitalDesignTokens.foundations.colors.gray800}
                            fontWeight={400}
                        >
                            Manage documents uploaded by vendors by approving or rejecting them.
                        </Typography>
                    </Alert>
                    <Box
                        className={styles.borderlessTable}
                        sx={{
                            borderLeft: `1px solid ${capitalDesignTokens.foundations.colors.terracotta600}`,
                            borderRight: `1px solid ${capitalDesignTokens.foundations.colors.terracotta600}`,
                            borderBottom: `1px solid ${capitalDesignTokens.foundations.colors.terracotta600}`,
                            py: 2,
                            px: 3,
                        }}
                    >
                        <AgGridReactLegacy
                            columns={columns}
                            defaultColDef={defaultColDef}
                            domLayout="autoHeight"
                            getRowNodeId={(data) => data.id}
                            hideSideBar
                            onGridReady={(params) => {
                                gridApiRef.current = params.api;
                            }}
                            rowHeight={52}
                            rows={rows}
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            )}
            {attachmentToReject && (
                <RejectionReasonModal
                    onHide={() => setAttachmentToReject(null)}
                    onSubmit={(data) => approvalHandler(attachmentToReject.id, false, data)}
                />
            )}
        </Box>
    );
};

export default PendingAttachments;
