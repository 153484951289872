import React, { useCallback, useState, useMemo } from 'react';
import { isEmpty, pick } from 'lodash';
import { v4 as UUIDv4 } from 'uuid';
import {
    Alert,
    AlertTitle,
    Dialog,
    Box,
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    ReduxFormTextField,
    ReduxFormSelect,
    Typography,
} from '@og-pro/ui';
import {
    Field,
    FieldArray,
    reduxForm,
    getFormSyncErrors,
    getFormValues,
    SubmissionError,
} from 'redux-form';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { useParams } from 'react-router-dom';
import { Error as ErrorIcon } from '@mui/icons-material';

import { uploadAndCreateContractAttachment } from '../../../../../actions/contracts';
import { StyledDropzone } from '../../../../../components/Dropzone/StyledDropzone';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { getContractAttachmentTagSelectOptions } from '../../../../selectors';
import { noTagsMessage } from '../../../../../components/forms/ContractForm/constants';
import { fieldNames } from '../../constants';
import { FileUploadedBox } from './file-uploaded-box';

const form = 'addContractAttachment';
const { FILENAME, TAGS, NOTES } = fieldNames;
const { colors } = capitalDesignTokens.foundations;

const FileFieldset = ({ uploading, fields, submitting, meta: { submitFailed } }) => {
    const uploadProgressObj = useSelector((state) =>
        state.contracts.get('contractAttachmentsBeingCreated')?.toJS()
    );
    const attachmentTags = useSelector(getContractAttachmentTagSelectOptions);

    return fields.map((member, i) => {
        const fileFormData = fields.get(i);

        if (fileFormData.uploaded) {
            // the only way we reach this condition is if some files were uploaded and then it failed. The UI changes in that case
            if (!uploading) {
                return null;
            }

            return <FileUploadedBox filename={fileFormData[FILENAME]} />;
        }

        if (uploadProgressObj[fileFormData.uploadId]) {
            const progress = parseInt(uploadProgressObj[fileFormData.uploadId].progress, 10) || 0;
            return (
                <Box
                    sx={{ border: `1px solid ${colors.gray200}`, borderRadius: '4px', p: 2, mb: 1 }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Box sx={{ width: '100%', marginRight: '12px' }}>
                            <LinearProgress value={progress} variant="determinate" />
                        </Box>
                        <Box
                            sx={{
                                marginTop: 1,
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography color="text.secondary" variant="body2">
                                Uploading {fileFormData[FILENAME]}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                {progress}%
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            );
        }

        return (
            <Box sx={{ border: `1px solid ${colors.gray200}`, borderRadius: '4px', p: 2, mb: 1 }}>
                <Grid container spacing={3} sx={{ mb: 2 }}>
                    <Grid item md={6} xs={12}>
                        <Field
                            aria-label="Edit File Name"
                            component={ReduxFormTextField}
                            disabled={submitting || uploading}
                            fullWidth
                            hasFeedback={false}
                            label="File Name"
                            name={`${member}.${FILENAME}`}
                            qaTag="modalAttachments-filename"
                            showValidation={submitFailed}
                            sx={{ '& .MuiInputBase-root': { mt: '1px !important' } }}
                            type="textarea"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Field
                            aria-label="Contract Document Type"
                            component={ReduxFormSelect}
                            disabled={submitting || uploading}
                            fullWidth
                            isClearable
                            label="Document Type"
                            menuPortalTarget={document?.body}
                            name={`${member}.${TAGS}`}
                            noOptionsMessage={noTagsMessage}
                            options={attachmentTags}
                            placeholder="Select"
                            qaTag="modalAttachments-type"
                            showValidation={submitFailed}
                        />
                    </Grid>
                </Grid>
                <Field
                    aria-label="Edit Notes"
                    component={ReduxFormTextField}
                    disabled={submitting || uploading}
                    fullWidth
                    hasFeedback={false}
                    label="Notes"
                    multiline
                    name={`${member}.${NOTES}`}
                    qaTag="modalAttachments-notes"
                    showValidation={submitFailed}
                    sx={{ mt: 2 }}
                    type="textarea"
                />
                {fileFormData.errored && (
                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', mt: 1 }}>
                        <ErrorIcon color="error" fontSize="small" />
                        <Typography color="error" variant="body2">
                            Something happened while we were uploading {fileFormData[FILENAME]}.
                            Please try again.
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    });
};

FileFieldset.propTypes = {
    uploading: PropTypes.bool.isRequired,
    fields: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    meta: PropTypes.object.isRequired,
};

const ErroredFilesAlert = ({ files }) => {
    const filesUploadIds = files.map((f) => f.uploadId);
    const values = useSelector((state) => getFormValues(form)(state));
    const fileNames = values.files
        .filter((f) => filesUploadIds.includes(f.uploadId))
        .map((f) => f[FILENAME]);

    return (
        <Box mt={3}>
            <Alert severity="error" variant="condensed">
                <AlertTitle>Missing Fields:</AlertTitle>
                <Typography variant="bodySmall">
                    There was an issue when trying to upload {fileNames.join(', ')}. Please try
                    again to complete the upload process.
                </Typography>
            </Alert>
        </Box>
    );
};

ErroredFilesAlert.propTypes = {
    files: PropTypes.array.isRequired,
};

const AddAttachmentDialogComponent = ({
    array,
    change,
    onClose,
    handleSubmit,
    onUploadComplete,
    submitting,
    submitFailed,
}) => {
    const { contractId } = useParams();
    const dispatch = useDispatch();
    const errors = useSelector((state) => getFormSyncErrors(form)(state));
    const { files } = useSelector((state) => getFormValues(form)(state) || { files: [] });

    const [uploading, setUploading] = useState(false);

    const dropHandler = useCallback(
        (selectedFiles) => {
            if (uploading) {
                return;
            }

            selectedFiles.forEach((file) => {
                const uploadId = UUIDv4();
                array.push('files', {
                    file,
                    uploaded: false,
                    uploadId,
                    [FILENAME]: file.name,
                    [TAGS]: null,
                    [NOTES]: '',
                    progress: 0,
                });
            });
        },
        [uploading, array]
    );

    const preSubmit = useCallback(
        async (values) => {
            // eslint-disable-next-line no-underscore-dangle
            if (errors.files?._error) {
                throw new SubmissionError(errors);
            }

            setUploading(true);

            let i = 0;

            while (i < values.files.length) {
                const file = values.files[i];

                if (file.uploaded) {
                    i++;

                    // eslint-disable-next-line no-continue
                    continue;
                }

                try {
                    // gets a signed url, uploads to s3 and saves the attachment via the API
                    const res = await dispatch(
                        uploadAndCreateContractAttachment(contractId, file.file, file.uploadId, {
                            ...pick(file, [FILENAME, NOTES]),
                            // the api expects an array of tags
                            tags: file[TAGS] ? [file[TAGS]] : null,
                        })
                    );

                    if (!res || res instanceof Error) {
                        throw new Error(res || `Failed to upload ${file[FILENAME]}`);
                    }

                    change(`files.${i}.uploaded`, true);
                    i++;
                } catch (err) {
                    change(`files.${i}.errored`, true);
                    break;
                }
            }

            setUploading(false);

            if (i === values.files.length) {
                return onUploadComplete(values.files.length);
            }
        },
        [change, contractId, dispatch, errors, onUploadComplete]
    );

    const selectedFilesLength = useMemo(() => files.length, [files]);
    const erroredFiles = useMemo(() => files.filter((f) => f.errored), [files]);
    const uploadedFiles = useMemo(() => files.filter((f) => f.uploaded), [files]);

    return (
        <Dialog
            allowOverflowedContent
            contentProps={{
                sx: { p: 0 },
            }}
            dialogTitle="Add Files"
            disableEscapeKeyDown={uploading}
            fullWidth
            maxWidth="md"
            onClose={() => {
                if (uploading) {
                    return;
                }

                onClose();
            }}
            open
            scroll="body"
        >
            <Box component="form" onSubmit={handleSubmit(preSubmit)}>
                <Box sx={{ p: 3, overflowY: 'auto', maxHeight: '80vh' }}>
                    {!uploading && (
                        <StyledDropzone
                            accept={{
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                    ['.docx'],
                                'application/msword': ['.doc', '.dot'],
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
                                    ['.dotx'],
                                'application/vnd.ms-word.document.macroEnabled.12': ['.docm'],
                                'text/plain': ['.txt'],
                                'text/csv': ['.csv'],
                                'application/pdf': ['.pdf'],
                                'application/vnd.oasis.opendocument.text': ['.odt'],
                                'application/rtf': ['.rtf'],
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                    ['.xlsx'],
                                'application/vnd.ms-excel': ['.xls'],
                                'image/jpeg': ['.jpeg', '.jpg'],
                                'image/png': ['.png'],
                                'image/gif': ['.gif'],
                                'image/bmp': ['.bmp'],
                                'text/html': ['.html', '.htm'],
                                'video/x-msvideo': ['.avi'],
                                'image/tiff': ['.tif', '.tiff'],
                                'application/vnd.accpac.simply.imp': ['.imp'],
                                'application/vnd.accpac.simply.exp': ['.exp'],
                                'application/acad': ['.dwg'],
                                'application/dxf': ['.dxf'],
                                'application/step': ['.step', '.stp'],
                                'application/iges': ['.iges', '.igs'],
                                'model/stl': ['.stl'],
                                'application/x-3ds': ['.3ds'],
                                'model/obj': ['.obj'],
                                'model/vnd.collada+xml': ['.dae'],
                                'application/x-inventor': ['.ipt', '.iam'],
                                'application/x-proe': ['.prt', '.asm'],
                                'application/x-catia': ['.catpart', '.catproduct'],
                            }}
                            defaultRejectMessage="File could not be uploaded."
                            error={
                                // eslint-disable-next-line no-underscore-dangle
                                errors.files?._error && submitFailed ? errors.files?._error : null
                            }
                            label="Choose Files to Upload"
                            onDropAccepted={dropHandler}
                            useOpenGovStyle
                        />
                    )}

                    {!!selectedFilesLength && (
                        <Box>
                            {/* upload started, failed at some point. There will be failed files */}
                            {!!erroredFiles.length && <ErroredFilesAlert files={erroredFiles} />}

                            {/* trying to upload without completing the name */}
                            {!!errors.files?.length && submitFailed && (
                                <Box mt={3}>
                                    <Alert severity="error" variant="condensed">
                                        <AlertTitle>Missing Fields:</AlertTitle>
                                        <Typography variant="bodySmall">
                                            File names are required for upload. Please update the
                                            fields below and try again.
                                        </Typography>
                                    </Alert>
                                </Box>
                            )}

                            {/* errored and we have some files that did uploaded */}
                            {!uploading && !!erroredFiles.length && !!uploadedFiles.length && (
                                <Box sx={{ mt: 3, mb: 1 }}>
                                    <Typography variant="h4">
                                        {uploadedFiles.length} of {selectedFilesLength} Files Were
                                        Successfully Uploaded
                                    </Typography>
                                    {uploadedFiles.map((f) => (
                                        <FileUploadedBox filename={f[FILENAME]} key={f.uploadId} />
                                    ))}
                                </Box>
                            )}

                            {/* files selected, waiting for the user to press upload */}
                            {!uploading && (
                                <Box sx={{ mt: 3, mb: 1 }}>
                                    <Typography variant="h4">Files Ready for Upload</Typography>
                                    <Typography variant="body2">
                                        These files have not been added yet. Optionally update the
                                        file names and add document types before finalizing the
                                        upload.
                                    </Typography>
                                </Box>
                            )}

                            {/* uploading files in progress */}
                            {uploading && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 3,
                                        mb: 1,
                                    }}
                                >
                                    <Typography variant="h4">Uploading Files</Typography>
                                    <Typography
                                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                    >
                                        <CircularProgress size="extraSmall" />
                                        &nbsp;Uploading {uploadedFiles.length + 1} of{' '}
                                        {selectedFilesLength}
                                    </Typography>
                                </Box>
                            )}
                            <FieldArray
                                component={FileFieldset}
                                files={files}
                                name="files"
                                uploading={uploading}
                            />
                        </Box>
                    )}
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    sx={{ borderTop: `1px solid ${colors.gray200}`, p: 3 }}
                >
                    <Box alignItems="center" display="flex" flex={2} justifyContent="flex-end">
                        <Box mr={1}>
                            <Button
                                disabled={submitting || uploading}
                                onClick={onClose}
                                qaTag="contractAttachments-cancel"
                                variant="text"
                            >
                                Cancel
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                disabled={submitting || uploading}
                                qaTag="contractAttachments-save"
                                sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                type="submit"
                                variant="contained"
                            >
                                {submitting && (
                                    <LoadingSpinner noPadding size="extraSmall" useOpenGovStyle />
                                )}
                                Upload Files
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

AddAttachmentDialogComponent.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onUploadComplete: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool,
};

const formConfig = {
    form,
    initialValues: {
        files: [],
    },
    validate: (values) => {
        const errors = { files: [] };

        values.files?.forEach((file, i) => {
            const innerErrrors = {};

            if (!file[FILENAME]) {
                innerErrrors[FILENAME] = 'File Name is required.';
            }

            if (!isEmpty(innerErrrors)) {
                errors.files[i] = innerErrrors;
            }
        });

        if (!values.files?.length) {
            // eslint-disable-next-line no-underscore-dangle
            errors.files._error = 'This field is required.';
        }

        return errors;
    },
    destroyOnUnmount: true,
};

export const AddAttachmentDialog = reduxForm(formConfig)(AddAttachmentDialogComponent);

AddAttachmentDialog.propTypes = {
    actions: PropTypes.node.isRequired,
    initialValues: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onUploadComplete: PropTypes.func.isRequired,
    title: PropTypes.string,
};
