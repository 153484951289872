import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from '@og-pro/ui';
import { Add as AddIcon } from '@mui/icons-material';

import { showSnackbar } from '../../../../../actions/notification';
import { AddAttachmentDialog } from './dialog';

export const AddAttachmentsButton = ({ onUploadSuccess }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const onUploadComplete = async (numberOfFiles) => {
        setOpen(false);
        dispatch(showSnackbar(`${numberOfFiles} files have been uploaded`, { isSuccess: true }));

        if (onUploadSuccess) {
            onUploadSuccess();
        }
    };

    return (
        <>
            {open && (
                <AddAttachmentDialog
                    onClose={() => setOpen(false)}
                    onUploadComplete={onUploadComplete}
                />
            )}
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                }}
                qaTag="contractDocuments-upload"
                startIcon={<AddIcon />}
                variant="outlined"
            >
                Add Files
            </Button>
        </>
    );
};

AddAttachmentsButton.propTypes = {
    onUploadSuccess: PropTypes.func,
};
