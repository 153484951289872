import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { EditAttachmentDialog } from './dialog';
import {
    deleteContractAttachment,
    updateContractAttachment,
} from '../../../../../actions/contracts';
import { showConfirmationSimpleModal } from '../../../../../actions/confirmation';
import { getContractJS } from '../../../../selectors';
import { fieldNames } from '../../constants';
import { showSnackbar } from '../../../../../actions/notification';

const { FILENAME, TAGS, NOTES } = fieldNames;

export const EditAttachment = ({ attachment, onFinish }) => {
    const dispatch = useDispatch();
    const contract = useSelector(getContractJS);

    const onSubmit = async (values) => {
        let tags = attachment[TAGS] || [];
        if (values[TAGS]) {
            tags = [values[TAGS]];
        }
        const notes = values[NOTES];

        const updatedAttachment = { ...values, tags, notes };

        await dispatch(updateContractAttachment(contract.id, attachment.id, updatedAttachment));

        dispatch(showSnackbar(`${updatedAttachment.filename} Updated`, { isSuccess: true }));
        return onFinish();
    };

    const handleDelete = async () => {
        dispatch(
            showConfirmationSimpleModal(
                async () => {
                    await dispatch(deleteContractAttachment(contract.id, attachment.id));

                    dispatch(showSnackbar(`${attachment.filename} Deleted`));

                    // avoid redux race condition
                    setTimeout(() => {
                        onFinish();
                    }, 1);
                },
                {
                    text: 'Are you sure you want to remove this document? This action is irreversible.',
                    useOpenGovStyle: true,
                }
            )
        );
    };

    if (!attachment) {
        return null;
    }

    return (
        <EditAttachmentDialog
            handleDelete={handleDelete}
            initialValues={{
                [FILENAME]: attachment[FILENAME],
                [TAGS]: attachment[TAGS].length ? attachment[TAGS][0].id : null,
                [NOTES]: attachment.contractAttachment[NOTES],
            }}
            onClose={() => onFinish()}
            onSubmit={onSubmit}
            open
            title="Edit Contract Document"
        />
    );
};

EditAttachment.propTypes = {
    attachment: PropTypes.object,
    onFinish: PropTypes.func.isRequired,
};
