import React, { useCallback } from 'react';
import { Dialog, Box, Button, Grid, ReduxFormTextField, ReduxFormSelect } from '@og-pro/ui';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { LoadingSpinner } from '../../../../../components';
import { getContractAttachmentTagSelectOptions } from '../../../../selectors';
import { noTagsMessage } from '../../../../../components/forms/ContractForm/constants';
import { fieldNames } from '../../constants';

const { FILENAME, TAGS, NOTES } = fieldNames;
const { colors } = capitalDesignTokens.foundations;

const EditAttachmentDialogComponent = ({
    open,
    onClose,
    handleDelete,
    handleSubmit,
    reset,
    submitting,
    submitFailed,
    title,
}) => {
    const attachmentTags = useSelector(getContractAttachmentTagSelectOptions);

    const handleClose = useCallback(() => {
        reset();
        onClose();
    }, []);

    if (!open) {
        return null;
    }

    return (
        <Dialog
            allowOverflowedContent
            contentProps={{
                sx: { p: 0 },
            }}
            dialogTitle={title}
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            open
            scroll="body"
        >
            <Box component="form" onSubmit={handleSubmit}>
                <Box sx={{ p: 3, pt: 2 }}>
                    <Grid container spacing={3} sx={{ mb: 2 }}>
                        <Grid item md={6} xs={12}>
                            <Field
                                aria-label="Edit File Name"
                                component={ReduxFormTextField}
                                disabled={submitting}
                                fullWidth
                                hasFeedback={false}
                                label="File Name"
                                name={FILENAME}
                                qaTag="modalAttachments-filename"
                                showValidation={submitFailed}
                                sx={{ '& .MuiInputBase-root': { mt: '1px !important' } }}
                                type="textarea"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Field
                                aria-label="Contract Document Type"
                                component={ReduxFormSelect}
                                disabled={submitting}
                                fullWidth
                                isClearable
                                label="Document Type"
                                menuPortalTarget={document?.body}
                                name={TAGS}
                                noOptionsMessage={noTagsMessage}
                                options={attachmentTags}
                                placeholder="Select"
                                qaTag="modalAttachments-type"
                                showValidation={submitFailed}
                            />
                        </Grid>
                    </Grid>
                    <Field
                        aria-label="Edit Notes"
                        component={ReduxFormTextField}
                        disabled={submitting}
                        fullWidth
                        hasFeedback={false}
                        label="Notes"
                        multiline
                        name={NOTES}
                        qaTag="modalAttachments-notes"
                        showValidation={submitFailed}
                        sx={{ mt: 2 }}
                        type="textarea"
                    />
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    sx={{ borderTop: `1px solid ${colors.gray200}`, p: 3 }}
                >
                    <Box flex={1}>
                        <Button
                            color="error"
                            disabled={submitting}
                            onClick={handleDelete}
                            qaTag="contractAttachments-delete"
                            variant="text"
                        >
                            Delete
                        </Button>
                    </Box>
                    <Box alignItems="center" display="flex" flex={2} justifyContent="flex-end">
                        <Box mr={1}>
                            <Button
                                disabled={submitting}
                                onClick={handleClose}
                                qaTag="contractAttachments-cancel"
                                variant="text"
                            >
                                Cancel
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                disabled={submitting}
                                qaTag="contractAttachments-save"
                                sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                type="submit"
                                variant="contained"
                            >
                                {submitting && (
                                    <LoadingSpinner noPadding size="extraSmall" useOpenGovStyle />
                                )}
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

EditAttachmentDialogComponent.propTypes = {
    handleDelete: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    title: PropTypes.string,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool,
};

const formConfig = {
    form: 'editContractAttachment',
    validate: (values) => {
        const errors = {};
        if (!values[FILENAME]) {
            errors[FILENAME] = 'File Name is required';
        }

        if (!values[TAGS]) {
            errors[TAGS] =
                'This field is required. You can be configure these types in the application settings.';
        }

        return errors;
    },
    destroyOnUnmount: true,
};

export const EditAttachmentDialog = reduxForm(formConfig)(EditAttachmentDialogComponent);

EditAttachmentDialog.propTypes = {
    handleDelete: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool,
    title: PropTypes.string,
};
