import React from 'react';
import { Box, LinearProgress, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

const { colors } = capitalDesignTokens.foundations;

export const FileUploadedBox = ({ filename }) => {
    return (
        <Box sx={{ border: `1px solid ${colors.gray200}`, borderRadius: '4px', p: 2, mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ width: '100%', marginRight: '12px' }}>
                    <LinearProgress color="success" value={100} variant="determined" />
                </Box>
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography color="text.secondary" variant="body2">
                        Uploaded {filename}
                    </Typography>
                    <CheckCircleIcon color="success" />
                </Box>
            </Box>
        </Box>
    );
};

FileUploadedBox.propTypes = {
    filename: PropTypes.string.isRequired,
};
