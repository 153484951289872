import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, InputLabel, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Error as ErrorIcon, Upload as UploadIcon } from '@mui/icons-material';

import { Dropzone } from '../Dropzone';
import { Label } from '../../InputText/Label';

const { colors } = capitalDesignTokens.foundations;

export class StyledDropzone extends PureComponent {
    static propTypes = {
        accept: PropTypes.object,
        children: PropTypes.node,
        className: PropTypes.string,
        defaultRejectMessage: PropTypes.string,
        dropzoneClassName: PropTypes.string,
        error: PropTypes.string,
        isDragActiveClassName: PropTypes.string,
        dropzoneContainerClassName: PropTypes.string,
        icons: PropTypes.arrayOf(PropTypes.string), // custom prop - specifies custom icons in dropzone
        label: PropTypes.string,
        labelId: PropTypes.string, // Used to associate `htmlFor` on `Label` with aria-labelledby on `Dropzone`
        multiple: PropTypes.bool,
        onDrop: PropTypes.func,
        onDropAccepted: PropTypes.func,
        onDropRejected: PropTypes.func,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // custom prop - specifies custom text to display in dropzone
        useCustomDropValidation: PropTypes.bool, // custom prop - used to bypass default drop error handling
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        defaultRejectMessage: 'File could not be uploaded',
        icons: ['file-pdf-o', 'file-photo-o', 'file-word-o'],
        text: 'Drop some files here or click to select files to upload.',
        useCustomDropValidation: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    onDropAccepted = (...args) => {
        const { onDropAccepted, useCustomDropValidation } = this.props;

        // Clear any errors unless using custom validation
        if (!useCustomDropValidation) {
            this.setState({ error: null });
        }

        if (onDropAccepted) {
            onDropAccepted(...args);
        }
    };

    onDropRejected = (...args) => {
        const { defaultRejectMessage, multiple, onDropRejected, useCustomDropValidation } =
            this.props;

        const [fileRejections] = args;

        let error = defaultRejectMessage;

        // Handle case where multiple files are uploaded in a dropzone where multiple not allowed
        // NOTE: `multiple` is true by default
        if (!multiple && fileRejections && fileRejections.length > 1) {
            error = 'Please upload a single file at a time';
        }

        // Set an error message unless using custom validation
        if (!useCustomDropValidation) {
            if (fileRejections.length > 0) {
                error = fileRejections;
            }

            this.setState({ error });
        }

        if (onDropRejected) {
            onDropRejected(...args);
        }
    };

    render() {
        const {
            accept,
            children,
            className,
            dropzoneClassName,
            dropzoneContainerClassName,
            isDragActiveClassName,
            icons,
            label,
            labelId,
            onDrop,
            text,
            useOpenGovStyle,
            ...props
        } = this.props;

        const { dropBox, dropIcons, dropText, labelContainer, ...styles } = require('./index.scss');

        const fileIcons = icons.map((icon) => <i className={`fa fa-lg fa-${icon}`} key={icon} />);

        const { error } = this.state;

        const dropzoneID = (label || labelId)?.replace(/ /g, '');

        if (useOpenGovStyle) {
            return (
                <Box className={styles.opengovDropzoneContainer}>
                    {!!label && (
                        <InputLabel
                            component="label"
                            htmlFor={dropzoneID}
                            sx={(theme) => ({
                                color: theme.palette.text.primary,
                                fontWeight: theme.typography.fontWeightMedium,
                                display: 'flex',
                                alignContent: 'center',
                                flexDirection: 'column',
                                lineHeight: 1.25,
                                marginBottom: 0.5,
                            })}
                        >
                            {label}
                        </InputLabel>
                    )}

                    <Dropzone
                        {...props}
                        accept={accept}
                        dropzoneID={dropzoneID}
                        fileRejectionErrors={error}
                        isDragActiveClassName={isDragActiveClassName}
                        onDrop={onDrop}
                        onDropAccepted={this.onDropAccepted}
                        onDropRejected={this.onDropRejected}
                        useOpenGovStyle
                    >
                        <Box
                            sx={{
                                backgroundColor: colors.gray50,
                                border: this.props.error
                                    ? `1px solid ${colors.ruby700}`
                                    : `1px solid ${colors.gray200}`,
                                borderRadius: '4px',
                                cursor: 'pointer',
                                padding: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    border: `1px dashed ${colors.gray700}`,
                                    borderRadius: '4px',
                                    padding: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        gap: 1,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <UploadIcon color={colors.gray800} fontSize="small" />
                                    <Typography color={colors.gray800}>
                                        Drag a file here to upload or&nbsp;
                                        <Box color={colors.brand800} component="span">
                                            click here to select files
                                        </Box>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        {!!this.props.error && (
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    color: colors.ruby700,
                                    display: 'flex',
                                    gap: 1,
                                    mt: 1,
                                }}
                            >
                                <ErrorIcon fontSize="small" />{' '}
                                <Typography variant="body2">{this.props.error}</Typography>
                            </Box>
                        )}
                    </Dropzone>
                </Box>
            );
        }
        return (
            <div className={className}>
                <Label className={labelContainer} htmlFor={dropzoneID}>
                    {label && <span>{label}</span>}
                </Label>

                <Dropzone
                    {...props}
                    accept={accept}
                    className={classnames(dropBox, { [dropzoneClassName]: !!dropzoneClassName })}
                    containerClassName={dropzoneContainerClassName}
                    dropzoneID={dropzoneID}
                    fileRejectionErrors={error}
                    isDragActiveClassName={isDragActiveClassName}
                    onDrop={onDrop}
                    onDropAccepted={this.onDropAccepted}
                    onDropRejected={this.onDropRejected}
                >
                    {!children ? (
                        <div className="text-center">
                            <div className={dropText}>{text}</div>
                            <div className={dropIcons}>{fileIcons}</div>
                        </div>
                    ) : (
                        children
                    )}
                </Dropzone>
            </div>
        );
    }
}
