import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';

import { fileExtensionValidator } from './validators';
import DropzoneErrorMessages, { FileRejectionErrorsPropType } from './DropzoneErrorMessages';

export function Dropzone({
    accept,
    className,
    children,
    containerClassName,
    isDragActiveClassName,
    dropzoneID,
    labelId,
    onDrop,
    onDropAccepted,
    onDropRejected,
    fileRejectionErrors,
    useOpenGovStyle,
    ...props
}) {
    const dropHandler = (acceptedFiles, rejectedFiles, dragEvent) => {
        if (rejectedFiles.length === 0 && onDropAccepted) {
            onDropAccepted(acceptedFiles);
        } else if (rejectedFiles.length && onDropRejected) {
            onDropRejected(rejectedFiles);
        }

        if (onDrop) {
            onDrop(acceptedFiles, rejectedFiles, dragEvent);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        ...props,
        accept,
        onDrop: dropHandler,
        validator: fileExtensionValidator,
    });

    const { dropzoneContainer, invalidFileBorder } = require('./Dropzone.scss');

    if (useOpenGovStyle) {
        return (
            <div>
                <div {...getRootProps({ className })}>
                    <input
                        id={dropzoneID}
                        {...getInputProps({ 'aria-labelledby': labelId || dropzoneID })}
                    />
                    {children}
                </div>
            </div>
        );
    }

    return (
        <div
            className={classnames(dropzoneContainer, {
                [fileRejectionErrors]: !!invalidFileBorder,
                [containerClassName]: !!containerClassName,
                [isDragActiveClassName]: isDragActive,
            })}
        >
            <div {...getRootProps({ className })}>
                <input
                    id={dropzoneID}
                    {...getInputProps({ 'aria-labelledby': labelId || dropzoneID })}
                />
                {children}
            </div>
            {fileRejectionErrors && (
                <DropzoneErrorMessages
                    defaultRejectMessage={props.defaultRejectMessage}
                    fileRejectionErrors={fileRejectionErrors}
                />
            )}
        </div>
    );
}

Dropzone.propTypes = {
    accept: PropTypes.object,
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    containerClassName: PropTypes.string,
    isDragActiveClassName: PropTypes.string,
    defaultRejectMessage: PropTypes.string,
    dropzoneID: PropTypes.string.isRequired,
    labelId: PropTypes.string,
    onDrop: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    fileRejectionErrors: FileRejectionErrorsPropType,
    useOpenGovStyle: PropTypes.bool,
};
