import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { isInitialClientLoaded } from '../../../containers/selectors';

const mapStateToProps = (state) => {
    return {
        isClientLoaded: isInitialClientLoaded(state),
    };
};
const ConnectedDocumentViewer = ({ isClientLoaded, url }) => {
    if (!isClientLoaded) {
        return null;
    }
    const styles = require('./DocumentViewer.scss');

    // Use Mozilla's hosted PDF viewer
    const viewerUrl = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(url)}`;
    console.log('viewerUrl', viewerUrl);
    console.log('url', url);
    console.log('encodeURIComponent(url)', encodeURIComponent(url));
    return (
        <div className={styles.viewerContainer}>
            <iframe
                className={styles.previewer}
                height="100%"
                onError={(e) => {
                    console.error('Error loading PDF viewer:', e);
                    console.error('e?.target?.src loading PDF viewer:', e?.target?.src);
                    console.error('e?.toString() loading PDF viewer:', e?.toString());
                }}
                src={viewerUrl}
                title="PDF viewer"
                width="100%"
            />
        </div>
    );
};
ConnectedDocumentViewer.propTypes = {
    isClientLoaded: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
};
export const DocumentViewer = connect(mapStateToProps)(ConnectedDocumentViewer);
